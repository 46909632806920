import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Layout from '@/views/Layout.vue'
import Profile from '@/views/Profile.vue'
import Reports from '@/views/Reports.vue'
import Payment from '@/views/Payment.vue'
import Tariffs from '@/views/Tariffs.vue'
import Services from '@/views/Services.vue'
import Parameters from '@/views/Parameters.vue'
import Contacts from '@/views/Contacts.vue'
import Movement from '@/views/Movement.vue'
import Abonement from '@/views/Abonement.vue'
import Block from '@/views/Block.vue'
import Software from '@/views/Software.vue'
import SocialInet from '@/views/SocialInet.vue'
import GameOption from '@/views/GameOption.vue'
import EsppResult from '@/views/EsppResult.vue'
import EsppAutopay from '@/views/EsppAutopay.vue'
import ElkDebug from '@/views/ElkDebug.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'login', component: Login, meta: {auth: false} },
  { path: '/', redirect: {name: 'profile'}, component: Layout, meta: {auth: true}, children: [
    { path: '/profile', name: 'profile', component: Profile, meta: {title: 'Профиль', inMenu: true}},
    { path: '/report/:type?', name: 'reports', component: Reports, meta: {title: 'Отчеты', inMenu: true}},
    { path: '/payment', name: 'payment', component: Payment, meta: {title: 'Пополнение счета', inMenu: true, auth: ['ROLE_PHYS', 'ROLE_JUR']} },
    { path: '/tariffs', name: 'tariffs', component: Tariffs, meta: {title: 'Тарифы', inMenu: true, auth: 'ROLE_PHYS'} },
    { path: '/services', name: 'services', component: Services, meta: {title: 'Услуги', inMenu: true, auth: ['ROLE_PHYS', 'ROLE_JUR']} },
    { path: '/parameters', name: 'parameters', component: Parameters, meta: {title: 'Настройка', inMenu: true, auth: ['ROLE_PHYS', 'ROLE_JUR']} },
    { path: '/notification', name: 'contacts', component: Contacts, meta: {title: 'Уведомления', inMenu: true, auth: ['ROLE_PHYS', 'ROLE_JUR']} },
    { path: '/pereezd', name: 'movement', component: Movement, meta: {title: 'Переезд', inMenu: true, auth: 'CAN_MOVEMENT'} },
    { path: '/action/subscribe', name: 'abonement', component: Abonement, meta: {title: 'Абонемент', inMenu: true, auth: 'CAN_ABONEMENT'} },
    { path: '/block', name: 'block', component: Block, meta: {title: 'Блокировка', auth: 'CAN_CHANGE_BLOCK'} },
    { path: '/software/:detailsId?', name: 'software', component: Software, meta: {title: 'Подписки', auth: 'CAN_SOFTWARE'} },
    { path: '/action/st', name: 'social-inet', component: SocialInet, meta: {title: 'Услуга «Социальный интернет»', auth: 'CAN_SOCIAL_INET'} },
    { path: '/action/game_option', name: 'game-option', component: GameOption, meta: {title: 'Тарифный план «Игровой»', auth: 'CAN_GAME_TARIFF'} },
    { path: '/bankcard/autopay', name: 'bankcard-autopay', component: EsppAutopay, meta: {title: 'Настройка автопополнения', auth: 'CAN_USE_AUTOPAY'} },

    { path: '/elk/debug', component: ElkDebug },
  ] },
  { path: '/bankcard/result', name: 'bankcard-result', component: EsppResult },
  { path: '*', redirect: {name: 'profile'} }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  if (store.state.forceReload) {
    window.location.reload(true)
  }
})

export default router
